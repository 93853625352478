import { getApiUrlByName } from 'project/helpers';
import { apiNames } from 'project/general';
import { baseApi } from 'project/api';

const GET_SEARCH_RESULTS = getApiUrlByName(apiNames.GET_SEARCH_RESULTS);

export class SearchService {


    static getSearchResults = (searchParameters) => {

        const config = { headers: { 'Content-Type': 'application/json' } };
        return baseApi
            .post(GET_SEARCH_RESULTS, JSON.stringify(searchParameters), config)
            .then((result) => {
                const data = result.data.data;
                return data;
            }).catch((err) => {
                console.error(err);
                return null;
            });

    }


}
